import * as React from 'react';
import { useParams } from 'react-router-dom';
import {
    PropertyContext,
    useSidapReportRequest,
    SidapSettings,
    useExportSettings,
    usePatchExportSettings,
    SubscriptionContext,
    API_URL,
} from '../api';
import { Switch, DatePicker } from '../components';
import { Base, PageParams } from './Base';
import { NotFound } from './NotFound';

export async function copyTextToClipboard(text: string) {
    if ('clipboard' in navigator) {
        return await navigator.clipboard.writeText(text);
    } else {
        return document.execCommand('copy', true, text);
    }
}

export function SidapExport(): JSX.Element {
    const { properties } = React.useContext(PropertyContext);
    const subscription = React.useContext(SubscriptionContext);
    const [state, setState] = React.useState<SidapSettings>(
        {} as SidapSettings,
    );
    const [date, setDate] = React.useState<Date | null>(new Date());
    const { id } = useParams<PageParams>();
    const property = properties.find((p) => p.code === id);
    const [copied, setCopied] = React.useState<boolean>(false);
    const {
        getReport,
        state: reportState,
        error: reportError,
    } = useSidapReportRequest(property ? property.code : '');
    const { data: initialData, state: initialDataState } =
        useExportSettings<SidapSettings>('sidap', id);
    const {
        patch,
        state: patchState,
        data: patchData,
    } = usePatchExportSettings<SidapSettings>('sidap', id);
    React.useEffect(() => {
        if (initialData) {
            setState(initialData);
        }
    }, [initialData]);
    React.useEffect(() => {
        if (patchData) {
            setState(patchData);
        }
    }, [patchData]);
    if (!property) {
        return <NotFound />;
    }
    const url =
        state.client_key && state.enabled
            ? `${API_URL}/apaleo/report/sidap-client/${state.client_key}`
            : '';
    const handleCopyClick = async () => {
        await copyTextToClipboard(url);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
    };
    return (
        <Base
            loading={
                initialDataState === 'loading' ||
                initialDataState === 'initial' ||
                reportState === 'loading'
            }
        >
            <h1 className="font-bold text-3xl">{property.name}</h1>
            <div className="flex flex-col border border-black rounded bg-white p-2">
                <div className="flex flex-row">
                    <h1 className="font-bold mr-2">SIDAP Export</h1>
                    <Switch
                        checked={
                            state.enabled && subscription.sidap.subscription
                        }
                        onChange={(v) => {
                            if (!subscription.sidap.subscription) {
                                subscription.sidapCheckoutRedirect();
                            } else {
                                setState({ ...state, enabled: v });
                                patch({ enabled: v });
                            }
                        }}
                        disabled={patchState === 'loading'}
                    >
                        Subscribe
                    </Switch>
                </div>
                <div className={`p-2 ${state.enabled ? '' : 'text-gray-500'}`}>
                    Client Access:
                    <div className="flex flex-row items-center">
                        {state.client_key && state.enabled ? (
                            <a
                                href={url}
                                className="underline mr-2"
                                target="_blank"
                                rel="noreferrer"
                            >
                                {url}
                            </a>
                        ) : (
                            <span
                                className={`italic mr-2 ${
                                    state.enabled ? '' : 'text-gray-500'
                                }`}
                            >
                                none
                            </span>
                        )}
                        <button
                            className="bg-gray-200 rounded border border-gray-500 disabled:text-gray-500 py-2 px-4 flex flex-row items-center justify-center mt-2"
                            disabled={
                                !state.enabled ||
                                patchState === 'loading' ||
                                !state.client_key
                            }
                            onClick={handleCopyClick}
                        >
                            {copied ? 'Done!' : 'Copy'}
                        </button>
                        <button
                            className="bg-gray-200 rounded border border-gray-500 disabled:text-gray-500 py-2 px-4 flex flex-row items-center justify-center mt-2"
                            disabled={
                                !state.enabled || patchState === 'loading'
                            }
                            onClick={() => patch({ client_key: 'new' })}
                        >
                            {state.client_key ? 'Reset' : 'Create'}
                        </button>
                        <button
                            className="bg-gray-200 rounded border border-gray-500 disabled:text-gray-500 py-2 px-4 flex flex-row items-center justify-center mt-2"
                            disabled={
                                !state.enabled ||
                                patchState === 'loading' ||
                                !state.client_key
                            }
                            onClick={() => patch({ client_key: null })}
                        >
                            Disable
                        </button>
                    </div>
                </div>
                <span className={state.enabled ? '' : 'text-gray-500'}>
                    Download CSV
                </span>
                <div className="p-2 w-1/2">
                    <div>
                        <DatePicker
                            value={date}
                            onChange={(date) => setDate(date)}
                            label="Date:"
                        />
                        <button
                            className="bg-gray-200 rounded border border-gray-500 disabled:text-gray-500 py-2 px-4 flex flex-row items-center justify-center mt-2"
                            disabled={
                                !state.enabled || reportState === 'loading'
                            }
                            onClick={() => getReport(date)}
                        >
                            <span className="mr-2">Export</span>
                        </button>
                    </div>
                    {reportState === 'error' && (
                        <span className="text-red-500">{reportError}</span>
                    )}
                </div>
            </div>
        </Base>
    );
}
