import * as React from 'react';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import {
    PropertyContext,
    useBfsReportRequest,
    usePatchExportSettings,
    useExportSettings,
    BfsSettings,
    SubscriptionContext,
} from '../api';
import { range } from '../utils';
import { TextField, NumberField, Checkbox, Switch } from '../components';
import { Base, PageParams } from './Base';
import { NotFound } from './NotFound';

export interface IOption<T> {
    value: T;
    label: string;
}

const currentYear = new Date().getFullYear();
const yearOptions: IOption<number>[] = range(
    currentYear - 2,
    currentYear + 1,
).map((year) => ({
    value: year,
    label: '' + year,
}));

const monthOptions: IOption<number>[] = range(1, 13).map((month) => ({
    value: month,
    label: '' + month,
}));

const docFormatOptions: IOption<string>[] = [
    { label: 'XML', value: 'xml' },
    { label: 'Excel', value: 'xlsx' },
    { label: 'JSON', value: 'json' },
];

export function BfsExport(): JSX.Element {
    const { properties } = React.useContext(PropertyContext);
    const subscription = React.useContext(SubscriptionContext);
    const [state, setState] = React.useState<BfsSettings>({} as BfsSettings);
    const { id } = useParams<PageParams>();
    const property = properties.find((p) => p.code === id);
    const [month, setMonth] = React.useState<IOption<number> | null>({
        value: 10,
        label: '10',
    });
    const [year, setYear] = React.useState<IOption<number> | null>({
        value: currentYear,
        label: '' + currentYear,
    });
    const {
        getReport,
        state: reportState,
        error: reportError,
    } = useBfsReportRequest(
        property ? property.code : '',
        year ? year.value : 0,
        month ? month.value : 0,
    );
    const { data: initialData, state: initialDataState } =
        useExportSettings<BfsSettings>('bfs', id);
    const { patch, state: patchState } = usePatchExportSettings<BfsSettings>(
        'bfs',
        id,
    );
    React.useEffect(() => {
        if (initialData) {
            setState(initialData);
        }
    }, [initialData]);
    const docFormatOption = docFormatOptions.find(
        (opt) => opt.value === state.format,
    );
    if (!property) {
        return <NotFound />;
    }
    return (
        <Base
            loading={
                initialDataState === 'loading' ||
                initialDataState === 'initial' ||
                reportState === 'loading'
            }
        >
            <h1 className="font-bold text-3xl">{property.name}</h1>
            <div className="flex flex-col border border-black rounded bg-white p-2">
                <div className="flex flex-row">
                    <h1 className="font-bold mr-2">BFS Export</h1>
                    <Switch
                        checked={state.enabled && subscription.bfs.subscription}
                        onChange={(v) => {
                            if (!subscription.bfs.subscription) {
                                subscription.bfsCheckoutRedirect();
                            } else {
                                setState({ ...state, enabled: v });
                                patch({ enabled: v });
                            }
                        }}
                        disabled={patchState === 'loading'}
                    >
                        Subscribe
                    </Switch>
                </div>
                <div className="w-1/2 p-2">
                    <TextField
                        name="BFS Identification Number"
                        value={state.bfs_id}
                        onChange={(v) => setState({ ...state, bfs_id: v })}
                        onBlur={() => patch({ bfs_id: state.bfs_id })}
                        disabled={!state.enabled || patchState === 'loading'}
                    />
                    <NumberField
                        name="Number of Rooms"
                        value={state.num_rooms}
                        onChange={(v) => setState({ ...state, num_rooms: v })}
                        onBlur={() => patch({ num_rooms: state.num_rooms })}
                        disabled={!state.enabled || patchState === 'loading'}
                    />
                    <NumberField
                        name="Number of beds"
                        value={state.num_beds}
                        onChange={(v) => setState({ ...state, num_beds: v })}
                        onBlur={() => patch({ num_beds: state.num_beds })}
                        disabled={!state.enabled || patchState === 'loading'}
                    />
                    <TextField
                        name="Contact First/Last Name"
                        value={state.contact_name}
                        onChange={(v) =>
                            setState({ ...state, contact_name: v })
                        }
                        onBlur={() =>
                            patch({ contact_name: state.contact_name })
                        }
                        disabled={!state.enabled || patchState === 'loading'}
                    />
                    <TextField
                        name="Contact phone number"
                        value={state.contact_phone}
                        onChange={(v) =>
                            setState({ ...state, contact_phone: v })
                        }
                        onBlur={() =>
                            patch({ contact_phone: state.contact_phone })
                        }
                        disabled={!state.enabled || patchState === 'loading'}
                    />
                    <TextField
                        name="Contact email"
                        value={state.contact_email}
                        onChange={(v) =>
                            setState({ ...state, contact_email: v })
                        }
                        onBlur={() =>
                            patch({ contact_email: state.contact_email })
                        }
                        disabled={!state.enabled || patchState === 'loading'}
                    />
                    <div className="flex flex-row items-center">
                        <span className="mr-2">Format:</span>
                        <Select
                            value={docFormatOption ? docFormatOption : null}
                            onChange={(opt) => {
                                if (opt) {
                                    setState({ ...state, format: opt.value });
                                    patch({ format: opt.value });
                                }
                            }}
                            options={docFormatOptions}
                            isDisabled={
                                !state.enabled || patchState === 'loading'
                            }
                        />
                    </div>
                </div>
                <Checkbox
                    checked={state.enable_emails}
                    onChange={(v) => {
                        setState({ ...state, enable_emails: v });
                        patch({ enable_emails: v });
                    }}
                    disabled={!state.enabled || patchState === 'loading'}
                >
                    Automatically send on the first of each month
                </Checkbox>
                <div className="w-1/2 p-2">
                    <TextField
                        name="Send mail to"
                        value={state.email_to}
                        onChange={(v) => setState({ ...state, email_to: v })}
                        onBlur={() => patch({ email_to: state.email_to })}
                        disabled={!state.enabled || patchState === 'loading'}
                    />
                    <TextField
                        name="Send mail cc"
                        value={state.email_cc}
                        onChange={(v) => setState({ ...state, email_cc: v })}
                        onBlur={() => patch({ email_cc: state.email_cc })}
                        disabled={!state.enabled || patchState === 'loading'}
                    />
                </div>
                <span>Download</span>
                <div className="p-2">
                    <div className="flex flex-row items-center">
                        <span className="mr-2">Year:</span>
                        <Select
                            value={year}
                            onChange={(opt) => setYear(opt)}
                            options={yearOptions}
                            isDisabled={
                                !state.enabled || patchState === 'loading'
                            }
                        />
                        <span className="mx-2">Month:</span>
                        <Select
                            value={month}
                            onChange={(opt) => setMonth(opt)}
                            options={monthOptions}
                            isDisabled={
                                !state.enabled || patchState === 'loading'
                            }
                        />
                    </div>
                    <div>
                        <button
                            className="bg-gray-200 rounded border border-gray-500 disabled:text-gray-500 py-2 px-4 flex flex-row items-center justify-center mt-2"
                            disabled={
                                !state.enabled ||
                                patchState === 'loading' ||
                                year === null ||
                                month === null ||
                                reportState === 'loading'
                            }
                            onClick={() => getReport()}
                        >
                            <span className="mr-2">Export</span>
                        </button>
                    </div>
                    {reportState === 'error' && (
                        <span className="text-red-500">{reportError}</span>
                    )}
                </div>
            </div>
        </Base>
    );
}
