import * as React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../assets/images/bedaya-logo.png';
import { useToken, SubscriptionContext } from '../api';
import { Spinner, Modal } from '../components';

interface BaseProps {
    children?: React.ReactNode;
    loading?: boolean;
}

export function Base({ children, loading }: BaseProps): JSX.Element {
    const { token, removeToken } = useToken();
    const subscription = React.useContext(SubscriptionContext);
    return (
        <div className="container mx-auto pt-16 flex flex-row">
            <div className="p-2 flex flex-col">
                <div className="bg-white p-2 rounded shadow-lg w-48">
                    <img src={Logo} alt="Bedaya Logo" />
                </div>
                {token && (
                    <>
                        <Link to="/properties" className="mt-2 hover:underline">
                            Properties
                        </Link>
                        <Link to="/history" className="mt-2 hover:underline">
                            History
                        </Link>
                        {subscription.state === 'ready' &&
                            (subscription.bfs.subscription ||
                                subscription.sidap.subscription) && (
                                <button
                                    onClick={() =>
                                        subscription.portalRedirect()
                                    }
                                    className="mt-2 hover:underline text-left"
                                >
                                    Subscriptions
                                </button>
                            )}
                        <button
                            onClick={() => {
                                removeToken();
                                window.location.reload();
                            }}
                            className="mt-2 hover:underline text-left"
                        >
                            Logout
                        </button>
                    </>
                )}
            </div>
            <div className="flex-grow py-2">{children}</div>
            <Modal isOpen={!!loading}>
                <div className="w-40 h-40 flex items-center justify-center">
                    <Spinner className="h-12 w-12" />
                </div>
            </Modal>
        </div>
    );
}

export interface PageParams {
    id: string;
}
