import axios, { AxiosError } from 'axios';
import { config } from '../config';

export const API_URL: string = config.backendUrl;

function logError(error: AxiosError): void {
    if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
    } else if (error.request) {
        console.log(error.request);
    } else {
        console.log(error.message);
    }
    console.log(error.config);
}

export async function axiosRequest<Type>(props: any): Promise<Type> {
    try {
        const response = await axios.request(props);
        return response.data as Type;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            logError(error);
        }
        throw error;
    }
}

export type LoadingState = 'initial' | 'loading' | 'ready' | 'error';
