import * as React from 'react';
import { Base } from './Base';
import * as api from '../api';

export function BexioOAuth(): JSX.Element {
    const { token } = api.useToken();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [error, setError] = React.useState<boolean>(false);
    React.useEffect(() => {
        const oauthCallback = async () => {
            try {
                const params = new URLSearchParams(window.location.search);
                if (params.has('code')) {
                    setError(false);
                    setLoading(true);
                    const redirect = await api.bexioOAuthCallback(token);
                    setLoading(false);
                    if (redirect) {
                        window.location.href = redirect;
                    } else {
                        setError(true);
                    }
                }
            } catch (err) {
                console.log(err);
                setError(true);
                setLoading(false);
            }
        };
        oauthCallback();
    }, [token]);
    return (
        <Base loading={loading}>
            <div className="min-h-full flex justify-center items-center">
                <div className="flex flex-col items-center">
                    {error && <h1>Error</h1>}
                </div>
            </div>
        </Base>
    );
}
