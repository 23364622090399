export interface TextFieldProps {
    name: string;
    value: string;
    onChange: (value: string) => void;
    onBlur: () => void;
    tabIndex?: number;
    placeholder?: string;
    disabled?: boolean;
}

export function TextField({
    name,
    value,
    onChange,
    onBlur,
    tabIndex = -1,
    placeholder = '',
    disabled = false,
}: TextFieldProps): JSX.Element {
    return (
        <div className="flex flex-row justify-between items-center my-1">
            <span className="mr-2">{name}:</span>
            <input
                type="text"
                className="px-2 py-1 bg-white focus:outline-none border border-black disabled:bg-gray-200"
                placeholder={placeholder}
                value={value ? value : ''}
                onChange={(e) => onChange(e.target.value)}
                onBlur={onBlur}
                tabIndex={tabIndex}
                disabled={disabled}
            />
        </div>
    );
}

export interface NumberFieldProps {
    name: string;
    value?: number;
    onChange: (value: number | undefined) => void;
    onBlur: () => void;
    tabIndex?: number;
    placeholder?: string;
    disabled?: boolean;
}

export function NumberField({
    name,
    value,
    onChange,
    onBlur,
    tabIndex = -1,
    placeholder = '',
    disabled = false,
}: NumberFieldProps): JSX.Element {
    return (
        <div className="flex flex-row justify-between items-center my-1">
            <span className="mr-2">{name}:</span>
            <input
                type="text"
                className="px-2 py-1 bg-white focus:outline-none border border-black disabled:bg-gray-200"
                placeholder={placeholder}
                value={value !== undefined ? value : ''}
                onChange={(e) => {
                    const value: string = e.target.value.replace(/\D/g, '');
                    onChange(value !== '' ? parseInt(value) : undefined);
                }}
                onBlur={onBlur}
                tabIndex={tabIndex}
                disabled={disabled}
            />
        </div>
    );
}
