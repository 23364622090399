import * as React from 'react';
import { Base } from './Base';
import Logo from '../assets/images/apaleo-logo.png';
import * as api from '../api';

export function Login(): JSX.Element {
    const [loading, setLoading] = React.useState<boolean>(false);
    const [error, setError] = React.useState<boolean>(false);
    const onLogin = async () => {
        try {
            setError(false);
            setLoading(true);
            const loginUrl = await api.getLoginUrl();
            setLoading(false);
            window.location.href = loginUrl;
        } catch (err) {
            console.log(err);
            setError(true);
            setLoading(false);
        }
    };
    React.useEffect(() => {
        const oauthCallback = async () => {
            try {
                const params = new URLSearchParams(window.location.search);
                if (params.has('code') && params.has('state')) {
                    setError(false);
                    setLoading(true);
                    const code = params.get('code') ?? '';
                    const state = params.get('state') ?? '';
                    const newToken = await api.oauthCallback(state, code);
                    setLoading(false);
                    window.localStorage.setItem(
                        'token',
                        JSON.stringify(newToken),
                    );
                    window.location.search = '';
                }
            } catch (err) {
                console.log(err);
                setError(true);
                setLoading(false);
            }
        };
        oauthCallback();
    }, []);
    return (
        <Base loading={loading}>
            <div className="min-h-full flex justify-center items-center">
                <div className="flex flex-col items-center">
                    <button
                        className="bg-white rounded mb-2 py-2 px-8 flex flex-row items-center shadow-lg hover:bg-gray-100 disabled:bg-gray-500"
                        disabled={loading}
                        onClick={onLogin}
                    >
                        <span className="mr-2">Login with apaleo</span>
                        <img
                            src={Logo}
                            alt="apaleo logo"
                            className="w-8 h-8 mr-2"
                        />
                    </button>
                    {error && <h1>Error</h1>}
                </div>
            </div>
        </Base>
    );
}
