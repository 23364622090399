import * as React from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export type DateChangeFn = (dt: Date | null) => void;

export interface IDatePicker {
    value: Date | null;
    onChange: DateChangeFn;
    label?: string;
    showError?: boolean;
    placeholder?: string;
}

export const DatePicker = ({
    value,
    onChange,
    label,
    showError,
    placeholder,
}: IDatePicker): JSX.Element => {
    const ref = React.createRef<HTMLInputElement>();

    const CustomInput = React.useMemo(
        () =>
            React.forwardRef<HTMLInputElement, unknown>(({ ...rest }, ref) => (
                <div className="">
                    {label && <label className="mr-2">{label}</label>}
                    <input
                        ref={ref}
                        {...rest}
                        className="border border-black px-2 py-1 focus:outline-none bg-white disabled:bg-gray-500"
                        type="text"
                        autoComplete="bday"
                        name="calendar"
                    />
                </div>
            )),
        [label],
    );

    return (
        <ReactDatePicker
            customInput={<CustomInput ref={ref} />}
            selected={value}
            dateFormat="yyyy-MM-dd"
            portalId="root"
            onChange={onChange}
            placeholderText={placeholder}
        />
    );
};
