import * as React from 'react';
import { Modal, Spinner } from '../components';
import {
    useGetSubscriptionStatus,
    usePostSubscriptionCheckout,
    ISubscription,
    SubscriptionContext,
    usePostSubscriptionPortal,
} from '../api';

export interface SubscriptionProviderProps {
    children?: React.ReactNode;
}

export function SubscriptionProvider({ children }: SubscriptionProviderProps) {
    const subscription = useGetSubscriptionStatus();
    const bfsCheckout = usePostSubscriptionCheckout('bfs');
    const sidapCheckout = usePostSubscriptionCheckout('sidap');
    const tessinCheckout = usePostSubscriptionCheckout('tessin');
    const nwCheckout = usePostSubscriptionCheckout('nw');
    const tourxCheckout = usePostSubscriptionCheckout('tourx');
    const portalRedirect = usePostSubscriptionPortal();

    const [data, setData] = React.useState<ISubscription>({
        bfs: {
            subscription: undefined,
            quantity: 0,
            status: '',
        },
        sidap: {
            subscription: undefined,
            quantity: 0,
            status: '',
        },
        tessin: {
            subscription: undefined,
            quantity: 0,
            status: '',
        },
        nw: {
            subscription: undefined,
            quantity: 0,
            status: '',
        },
        tourx: {
            subscription: undefined,
            quantity: 0,
            status: '',
        },
        state: 'initial',
        error: 0,
        bfsCheckoutRedirect: () =>
            bfsCheckout.post({ url: window.location.href }),
        sidapCheckoutRedirect: () =>
            sidapCheckout.post({ url: window.location.href }),
        tessinCheckoutRedirect: () =>
            tessinCheckout.post({ url: window.location.href }),
        nwCheckoutRedirect: () =>
            nwCheckout.post({ url: window.location.href }),
        tourxCheckoutRedirect: () =>
            tourxCheckout.post({ url: window.location.href }),
        portalRedirect: () =>
            portalRedirect.post({ url: window.location.href }),
    });
    React.useEffect(() => {
        if (subscription.state === 'ready') {
            setData((d) => ({
                ...d,
                ...subscription.data,
                state: subscription.state,
                error: subscription.error,
            }));
        } else {
            setData((d) => ({
                ...d,
                state: subscription.state,
                error: subscription.error,
            }));
        }
    }, [subscription.state, subscription.data, subscription.error]);
    React.useEffect(() => {
        if (bfsCheckout.state === 'ready' && bfsCheckout.data) {
            window.location.href = bfsCheckout.data.url;
        }
    }, [bfsCheckout.state, bfsCheckout.data]);
    React.useEffect(() => {
        if (sidapCheckout.state === 'ready' && sidapCheckout.data) {
            window.location.href = sidapCheckout.data.url;
        }
    }, [sidapCheckout.state, sidapCheckout.data]);
    React.useEffect(() => {
        if (tessinCheckout.state === 'ready' && tessinCheckout.data) {
            window.location.href = tessinCheckout.data.url;
        }
    }, [tessinCheckout.state, tessinCheckout.data]);
    React.useEffect(() => {
        if (nwCheckout.state === 'ready' && nwCheckout.data) {
            window.location.href = nwCheckout.data.url;
        }
    }, [nwCheckout.state, nwCheckout.data]);
    React.useEffect(() => {
        if (tourxCheckout.state === 'ready' && tourxCheckout.data) {
            window.location.href = tourxCheckout.data.url;
        }
    }, [tourxCheckout.state, tourxCheckout.data]);
    React.useEffect(() => {
        if (portalRedirect.state === 'ready' && portalRedirect.data) {
            window.location.href = portalRedirect.data.url;
        }
    }, [portalRedirect.state, portalRedirect.data]);
    return (
        <SubscriptionContext.Provider value={data}>
            {children}
            <Modal
                isOpen={
                    bfsCheckout.state === 'loading' ||
                    sidapCheckout.state === 'loading' ||
                    tessinCheckout.state === 'loading' ||
                    nwCheckout.state === 'loading' ||
                    tourxCheckout.state === 'loading' ||
                    portalRedirect.state === 'loading'
                }
            >
                <div className="w-40 h-40 flex items-center justify-center">
                    <Spinner className="h-12 w-12" />
                </div>
            </Modal>
        </SubscriptionContext.Provider>
    );
}
