import * as React from 'react';
import { Link } from 'react-router-dom';
import { Base } from './Base';
import { PropertyContext } from '../api';

interface PropertyBadgeProps {
    code: string;
    name: string;
}

function PropertyBadge({ code, name }: PropertyBadgeProps): JSX.Element {
    return (
        <Link to={`/properties/${code}/`}>
            <div className="border-1 border-black shadow-lg w-40 h-40 p-2 bg-white m-2 rounded flex flex-col">
                <span className="font-bold">{code}</span>
                <span className="">{name}</span>
            </div>
        </Link>
    );
}

export function PropertyList(): JSX.Element {
    const { properties } = React.useContext(PropertyContext);
    return (
        <Base>
            <div className="min-h-full flex flex-col">
                <h1 className="font-bold text-3xl">Your Properties</h1>
                <div className="flex flex-row flex-wrap">
                    {properties.map((it) => (
                        <PropertyBadge key={it.id} {...it} />
                    ))}
                </div>
            </div>
        </Base>
    );
}
