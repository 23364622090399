export interface CheckboxProps {
    className?: string;
    children?: React.ReactNode;
    checked?: boolean;
    onChange: (checked: boolean) => void;
    disabled?: boolean;
}

export function Checkbox({
    checked,
    children,
    className,
    onChange,
    disabled,
    ...rest
}: CheckboxProps) {
    return (
        <div className={`form-check max-w-[200px] ${className}`}>
            <input
                className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                type="checkbox"
                value=""
                onChange={(e) => onChange(e.target.checked)}
                checked={!!checked}
                disabled={!!disabled}
            />
            <label className="form-check-label inline-block text-gray-800">
                {children}
            </label>
        </div>
    );
}
