import * as React from 'react';
import { useParams } from 'react-router-dom';
import {
    PropertyContext,
    useTourxReportRequest,
    useTourxApiRequest,
    TourxSettings,
    useExportSettings,
    usePatchExportSettings,
    SubscriptionContext,
} from '../api';
import {
    Switch,
    DatePicker,
    TextField,
    Checkbox,
    NumberField,
} from '../components';
import { Base, PageParams } from './Base';
import { NotFound } from './NotFound';

export function TourxExport(): JSX.Element {
    const { properties } = React.useContext(PropertyContext);
    const subscription = React.useContext(SubscriptionContext);
    const [state, setState] = React.useState<TourxSettings>(
        {} as TourxSettings,
    );
    const [date, setDate] = React.useState<Date | null>(new Date());
    const { id } = useParams<PageParams>();
    const property = properties.find((p) => p.code === id);
    const {
        getReport,
        state: reportState,
        error: reportError,
    } = useTourxReportRequest(property ? property.code : '');
    const {
        callApi,
        state: callApiState,
        error: callApiError,
    } = useTourxApiRequest(property ? property.code : '');
    const { data: initialData, state: initialDataState } =
        useExportSettings<TourxSettings>('tourx', id);
    const {
        patch,
        state: patchState,
        data: patchData,
    } = usePatchExportSettings<TourxSettings>('tourx', id);
    React.useEffect(() => {
        if (initialData) {
            setState(initialData);
        }
    }, [initialData]);
    React.useEffect(() => {
        if (patchData) {
            setState(patchData);
        }
    }, [patchData]);
    if (!property) {
        return <NotFound />;
    }
    return (
        <Base
            loading={
                initialDataState === 'loading' ||
                initialDataState === 'initial' ||
                reportState === 'loading'
            }
        >
            <h1 className="font-bold text-3xl">{property.name}</h1>
            <div className="flex flex-col border border-black rounded bg-white p-2">
                <div className="flex flex-row">
                    <h1 className="font-bold mr-2">TourX</h1>
                    <Switch
                        checked={
                            state.enabled && subscription.tourx.subscription
                        }
                        onChange={(v) => {
                            if (!subscription.tourx.subscription) {
                                subscription.tourxCheckoutRedirect();
                            } else {
                                setState({ ...state, enabled: v });
                                patch({ enabled: v });
                            }
                        }}
                        disabled={patchState === 'loading'}
                    >
                        Subscribe
                    </Switch>
                </div>
                <div className="w-1/2 p-2">
                    <TextField
                        name="API-user"
                        value={state.api_user}
                        onChange={(v) => setState({ ...state, api_user: v })}
                        onBlur={() => patch({ api_user: state.api_user })}
                        disabled={!state.enabled || patchState === 'loading'}
                    />
                    <TextField
                        name="API-key"
                        value={state.api_key}
                        onChange={(v) => setState({ ...state, api_key: v })}
                        onBlur={() => patch({ api_key: state.api_key })}
                        disabled={!state.enabled || patchState === 'loading'}
                    />
                </div>
                <Checkbox
                    checked={state.enable_api}
                    onChange={(v) => {
                        setState({ ...state, enable_api: v });
                        patch({ enable_api: v });
                    }}
                    disabled={
                        !state.enabled ||
                        !state.api_key ||
                        !state.api_user ||
                        patchState === 'loading'
                    }
                >
                    Automatically call API daily
                </Checkbox>
                <div className="w-1/2 p-2">
                    <NumberField
                        name="Days in advance"
                        value={state.days_advance}
                        onChange={(v) =>
                            setState({ ...state, days_advance: v })
                        }
                        onBlur={() =>
                            patch({ days_advance: state.days_advance })
                        }
                        disabled={!state.enabled || patchState === 'loading'}
                    />
                </div>
                <span className={state.enabled ? '' : 'text-gray-500'}>
                    Download JSON
                </span>
                <div className="p-2 w-1/2">
                    <div>
                        <DatePicker
                            value={date}
                            onChange={(date) => setDate(date)}
                            label="Date:"
                        />
                        <button
                            className="bg-gray-200 rounded border border-gray-500 disabled:text-gray-500 py-2 px-4 flex flex-row items-center justify-center mt-2"
                            disabled={
                                !state.enabled || reportState === 'loading'
                            }
                            onClick={() => getReport(date)}
                        >
                            <span className="mr-2">Export</span>
                        </button>
                    </div>
                    {reportState === 'error' && (
                        <span className="text-red-500">{reportError}</span>
                    )}
                </div>
                <span className={state.enabled ? '' : 'text-gray-500'}>
                    Call API
                </span>
                <div className="p-2 w-1/2">
                    <button
                        className="bg-gray-200 rounded border border-gray-500 disabled:text-gray-500 py-2 px-4 flex flex-row items-center justify-center mt-2"
                        disabled={
                            !state.enabled ||
                            !state.api_user ||
                            !state.api_key ||
                            reportState === 'loading'
                        }
                        onClick={() => callApi()}
                    >
                        <span className="mr-2">Submit</span>
                    </button>
                    {callApiState === 'error' && (
                        <span className="text-red-500">{callApiError}</span>
                    )}
                    {callApiState === 'ready' && <span className="">Done</span>}
                </div>
            </div>
        </Base>
    );
}
