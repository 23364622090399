import * as React from 'react';
import { Base } from './Base';
import { useHistory } from '../api';
import { IOption } from './BfsExport';
import Select from 'react-select';
import { range } from '../utils';

function convertDate(text: string): string {
    const dt = new Date(Date.parse(text));
    return dt.toLocaleDateString();
}

const perPageOptions: IOption<number>[] = [
    { value: 10, label: '10' },
    { value: 50, label: '50' },
];

const filterOptions: IOption<string>[] = [
    { value: 'bfs-report', label: 'bfs-report' },
    { value: 'sidap-report', label: 'sidap-report' },
];

export function History(): JSX.Element {
    const [perPage, setPerPage] = React.useState<IOption<number> | null>(
        perPageOptions[0],
    );
    const [page, setPage] = React.useState<IOption<number> | null>({
        value: 1,
        label: '1',
    });
    const [filter, setFilter] = React.useState<IOption<string> | null>({
        value: 'bfs-report',
        label: 'bfs-report',
    });
    const [pages, setPages] = React.useState<IOption<number>[]>([]);
    const { data, state, error } = useHistory(
        page ? page.value : 1,
        perPage ? perPage.value : 10,
        filter ? filter.value : 'bfs-report',
    );
    React.useEffect(() => {
        if (state === 'ready' && data && perPage) {
            const numPages = Math.ceil(+data.count / perPage.value);
            const newPages = range(1, numPages + 1).map((it) => ({
                value: it,
                label: '' + it,
            }));
            setPages(newPages);
        }
    }, [state, data, perPage]);
    return (
        <Base loading={state === 'initial' || state === 'loading'}>
            <h1 className="font-bold text-3xl">History page</h1>
            {state === 'error' && (
                <span className="text-red-500">Error: {error}</span>
            )}
            {state === 'ready' && (
                <>
                    <table className="table-auto w-full md:w-1/2">
                        <thead>
                            <tr>
                                <th className="text-left">Date</th>
                                <th className="text-right">Topic</th>
                                <th className="text-right">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data &&
                                data.result.map((it) => (
                                    <tr key={it._id}>
                                        <td className="text-left">
                                            {convertDate(it.started)}
                                        </td>
                                        <td className="text-right">
                                            {it.topic}
                                        </td>
                                        <td className="text-right">
                                            {it.exitState}
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                    <div>
                        Filter:{' '}
                        <Select
                            className="w-40"
                            value={filter}
                            onChange={(opt) => setFilter(opt)}
                            options={filterOptions}
                        />
                    </div>
                    <div>
                        Page:{' '}
                        <Select
                            className="w-20"
                            value={page}
                            onChange={(opt) => setPage(opt)}
                            options={pages}
                        />
                    </div>
                    <div>
                        Per page:{' '}
                        <Select
                            className="w-20"
                            value={perPage}
                            onChange={(opt) => setPerPage(opt)}
                            options={perPageOptions}
                        />
                    </div>
                </>
            )}
        </Base>
    );
}
