import * as React from 'react';
import { Modal, Checkbox, Button, Spinner } from '../components';
import {
    UserInfo,
    UserInfoContext,
    IUserInfoContext,
    useGetUserInfo,
    usePutUserInfo,
    useToken,
} from '../api';
import { config } from '../config';

export interface UserInfoProviderProps {
    children?: React.ReactNode;
}

export function UserInfoProvider({ children }: UserInfoProviderProps) {
    const userInfo = useGetUserInfo();
    const patch = usePutUserInfo();
    const [data, setData] = React.useState<IUserInfoContext>({
        language: 'en',
        privacyPolicies: undefined,
        termsAndConditions: undefined,
        state: 'initial',
        error: 0,
        onUpdate: patch.patch,
    });
    React.useEffect(
        function () {
            if (userInfo.state === 'ready') {
                setData((d) => ({
                    ...d,
                    ...userInfo.data,
                    state: userInfo.state,
                    error: userInfo.error,
                }));
            } else {
                setData((d) => ({
                    ...d,
                    state: userInfo.state,
                    error: userInfo.error,
                }));
            }
        },
        [userInfo.state, userInfo.data, userInfo.error],
    );
    React.useEffect(
        function () {
            if (patch.state === 'ready') {
                setData((d) => ({
                    ...d,
                    ...patch.data,
                    state: patch.state,
                    error: patch.error,
                }));
            } else {
                setData((d) => ({
                    ...d,
                    state: patch.state,
                    error: patch.error,
                }));
            }
        },
        [patch.state, patch.data, patch.error],
    );
    return (
        <UserInfoContext.Provider value={data}>
            {children}
            <TermsGuard />
        </UserInfoContext.Provider>
    );
}

export function TermsGuard() {
    const userInfo = React.useContext(UserInfoContext);
    const termsEnabled = config.termsVersion && config.termsUrl;
    if (termsEnabled && userInfo.termsAndConditions) {
        const hasUnconfirmed = !!userInfo.termsAndConditions.find(
            (terms: any) => !terms.confirmed,
        );
        const hasNoCurrent = !userInfo.termsAndConditions.find(
            (terms: any) => terms.version === config.termsVersion,
        );
        if (hasUnconfirmed || hasNoCurrent) {
            return <TermsDialog />;
        }
    }
    return null;
}

export function TermsDialog() {
    const [checked, setChecked] = React.useState(false);
    const userInfo = React.useContext(UserInfoContext);
    const { removeToken } = useToken();
    const onConfirm = () => {
        const data: UserInfo = {} as UserInfo;
        const now = new Date().toISOString();
        if (!userInfo.termsAndConditions) {
            data.termsAndConditions = [
                { version: config.termsVersion, confirmed: now },
            ];
        } else {
            const hasUnconfirmed = userInfo.termsAndConditions.find(
                (terms: any) => !terms.confirmed,
            );
            const hasNoCurrent = !userInfo.termsAndConditions.find(
                (terms: any) => terms.version === config.termsVersion,
            );
            if (hasNoCurrent) {
                data.termsAndConditions = [
                    ...userInfo.termsAndConditions.map((x) => ({
                        ...x,
                        confirmed: x.confirmed ? x.confirmed : now,
                    })),
                    { version: config.termsVersion, confirmed: now },
                ];
            } else if (hasUnconfirmed) {
                data.termsAndConditions = userInfo.termsAndConditions.map(
                    (x) => ({
                        ...x,
                        confirmed: x.confirmed ? x.confirmed : now,
                    }),
                );
            }
        }
        userInfo.onUpdate(data);
    };
    const onCancel = () => {
        removeToken();
        window.location.reload();
    };
    return (
        <Modal isOpen>
            <div className="p-2 text-center">
                <div className="p-2 text-xl font-bold border-gray-200 border-b">
                    General Terms and Conditions
                </div>
                <div className="p-2">
                    <div className="mb-2">
                        Please read our Terms and conditions{' '}
                        <a
                            href={config.termsUrl}
                            target="_blank"
                            rel="noreferrer"
                            className="underline hover:text-gray-500"
                        >
                            here
                        </a>
                    </div>
                    <Checkbox checked={checked} onChange={setChecked}>
                        I agree with terms and conditions
                    </Checkbox>
                </div>
                <div className="p-2 flex flex-row border-gray-200 border-t">
                    <div className="flex-grow" />
                    <Button className="mr-2" onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button
                        className=""
                        disabled={!checked}
                        onClick={onConfirm}
                    >
                        <>
                            <span className="mr-2">Next</span>
                            {userInfo.state === 'loading' && (
                                <Spinner className="h-4 w-4" />
                            )}
                            {userInfo.error !== 0 && (
                                <span className="text-red-500" />
                            )}
                        </>
                    </Button>
                </div>
            </div>
        </Modal>
    );
}
