import * as React from 'react';
import { useLocalStorage } from './useLocalStorage';
import jwt_decode, { JwtPayload } from 'jwt-decode';

export interface IToken {
    token: string | null;
    saveToken(newToken: string): void;
    removeToken(): void;
}

export function useToken(): IToken {
    const [token, setToken] = useLocalStorage<string | null>('token', null);
    React.useEffect(() => {
        if (token && token.length && !isValidToken(token)) {
            setToken(null);
        }
    }, [token, setToken]);
    const saveToken = (newToken: string): void => setToken(newToken);
    const removeToken = (): void => setToken(null);
    return { token, saveToken, removeToken };
}

export function isValidToken(token: string): boolean {
    if (!token || token.length === 0) {
        return false;
    }
    const decoded: JwtPayload = jwt_decode(token);
    if (!decoded.exp) {
        return true;
    }
    const expires = decoded.exp * 1000;
    return Date.now() < expires;
}
