import ReactModal from 'react-modal';

export interface ModalProps {
    children?: React.ReactNode;
    isOpen: boolean;
    onRequestClose?: () => void;
}

export function Modal({ children, ...props }: ModalProps) {
    return (
        <ReactModal
            className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded shadow-2xl outline-none overflow-y-auto"
            overlayClassName="fixed inset-0 bg-black bg-opacity-25 z-10"
            bodyOpenClassName="overflow-hidden"
            htmlOpenClassName="overflow-hidden"
            {...props}
        >
            {children}
        </ReactModal>
    );
}

export default Modal;
