import * as React from 'react';
import { useParams } from 'react-router-dom';
import {
    PropertyContext,
    BexioAccountingSettings,
    useExportSettings,
    usePatchExportSettings,
    useBexioConnectionTest,
    getBexioLoginUrl,
    useToken,
    useBexioAccountingSchema,
    useAccountingSchema,
    AccountingModel,
    BexioAccount,
} from '../api';
import { Switch, Button } from '../components';
import { Base, PageParams } from './Base';
import { NotFound } from './NotFound';
import Select from 'react-select';

interface LevelOption {
    value: string;
    label: string;
    isDisabled: boolean;
}

interface GroupedOption {
    label: string;
    options: LevelOption[];
}

const groupBy = <T, K extends keyof any>(arr: T[], key: (i: T) => K) =>
    arr.reduce((groups, item) => {
        (groups[key(item)] = groups[key(item)] || []).push(item);
        return groups;
    }, {} as Record<K, T[]>);

const flatten = <T, K extends T>(arr: T[], children: (i: T) => T[]) =>
    arr.reduce((result: K[], item: T) => {
        result.push(item as K);
        const sub = children(item);
        if (sub && sub.length) {
            for (const s of flatten(sub, children)) {
                result.push(s as K);
            }
        }
        return result;
    }, [] as K[]);

const convertAccountingModel = (arr: AccountingModel[]) =>
    flatten(arr, (x) => (x.subAccounts ? x.subAccounts : [])).map((x) => ({
        value: x.accountNumber,
        label: x.name,
        isDisabled: false,
    }));

const convertBexioAccountingModel = (arr: BexioAccount[]) => {
    const groups = groupBy(arr, (x) => x.type);
    return Object.keys(groups).map((key) => ({
        label: key,
        options: groups[key].map((x) => ({
            value: x.account_no,
            label: `${x.account_no} ${x.name}`,
            isDisabled: false,
        })),
    }));
};

const mapDisabled = (arr: GroupedOption[], selected: string[]) =>
    arr.map((item) => ({
        ...item,
        options: item.options.map((x) => ({
            ...x,
            isDisabled: selected.find((i) => i === x.value) !== undefined,
        })),
    }));

const findOption = (arr: GroupedOption[], val: string | undefined) => {
    if (!val) {
        return null;
    }
    for (const gr of arr) {
        const options = gr.options;
        const item = options.find((x) => x.value === val);
        if (item) {
            return item;
        }
    }
    return null;
};

interface RowProps {
    aOptions: GroupedOption[];
    bOptions: GroupedOption[];
    a?: string;
    b?: string;
    onChange: (a: string, b: string) => void;
    onDelete?: () => void;
    isDisabled: boolean;
}

function Row(props: RowProps): JSX.Element {
    const { aOptions, bOptions, a, b, onChange, onDelete, isDisabled } = props;
    const [aOption, setA] = React.useState<LevelOption | null>(null);
    const [bOption, setB] = React.useState<LevelOption | null>(null);
    React.useEffect(() => {
        setA(findOption(aOptions, a));
    }, [a, aOptions]);
    React.useEffect(() => {
        setB(findOption(bOptions, b));
    }, [b, bOptions]);
    return (
        <div className="flex flex-row items-center">
            <Select<LevelOption, false, GroupedOption>
                value={aOption}
                options={aOptions}
                onChange={(opt) => {
                    setA(opt);
                    if (opt && bOption) {
                        onChange(opt.value, bOption.value);
                    }
                }}
                isDisabled={isDisabled}
                className="w-60 mr-2"
            />
            <Select<LevelOption, false, GroupedOption>
                value={bOption}
                options={bOptions}
                onChange={(opt) => {
                    setB(opt);
                    if (aOption && opt) {
                        onChange(aOption.value, opt.value);
                    }
                }}
                isDisabled={isDisabled}
                className="w-60 mr-2"
            />
            {a && b && onDelete && (
                <Button onClick={onDelete} disabled={isDisabled} className="">
                    X
                </Button>
            )}
        </div>
    );
}

interface DefaultAccountsProps {
    options: GroupedOption[];
    debitAcc?: string;
    creditAcc?: string;
    isDisabled: boolean;
    onChange: (
        debitAcc: string | undefined,
        creditAcc: string | undefined,
    ) => void;
}

function DefaultAccounts(props: DefaultAccountsProps) {
    const { options, debitAcc, creditAcc, isDisabled, onChange } = props;
    const [debitAccOpt, setDebitAccOpt] = React.useState<LevelOption | null>(
        null,
    );
    const [creditAccOpt, setCreditAccOpt] = React.useState<LevelOption | null>(
        null,
    );
    React.useEffect(() => {
        setDebitAccOpt(findOption(options, debitAcc));
    }, [debitAcc, options]);
    React.useEffect(() => {
        setCreditAccOpt(findOption(options, creditAcc));
    }, [creditAcc, options]);
    return (
        <div className="flex flex-col">
            <div className="flex flex-row items-center">
                <label>Default debit account:</label>
                <Select<LevelOption, false, GroupedOption>
                    value={debitAccOpt}
                    options={options}
                    onChange={(opt) => {
                        setDebitAccOpt(opt);
                        onChange(opt?.value, creditAccOpt?.value);
                    }}
                    isDisabled={isDisabled}
                    className="w-60 mr-2"
                />
            </div>
            <div className="flex flex-row items-center">
                <label>Default credit account:</label>
                <Select<LevelOption, false, GroupedOption>
                    value={creditAccOpt}
                    options={options}
                    onChange={(opt) => {
                        setCreditAccOpt(opt);
                        onChange(debitAccOpt?.value, opt?.value);
                    }}
                    isDisabled={isDisabled}
                    className="w-60 mr-2"
                />
            </div>
        </div>
    );
}

export function BexioAccounting(): JSX.Element {
    const { token } = useToken();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [error, setError] = React.useState<boolean>(false);
    const { properties } = React.useContext(PropertyContext);
    const [state, setState] = React.useState<BexioAccountingSettings>(
        {} as BexioAccountingSettings,
    );
    const { id } = useParams<PageParams>();
    const property = properties.find((p) => p.code === id);
    const bexioState = useBexioConnectionTest();
    const { data: initialData, state: initialDataState } =
        useExportSettings<BexioAccountingSettings>('bexio-accounting', id);
    const {
        patch,
        state: patchState,
        data: patchData,
    } = usePatchExportSettings<BexioAccountingSettings>('bexio-accounting', id);
    React.useEffect(() => {
        if (initialData) {
            setState(initialData);
        }
    }, [initialData]);
    React.useEffect(() => {
        if (patchData) {
            setState(patchData);
        }
    }, [patchData]);
    const onLogin = async () => {
        try {
            setError(false);
            setLoading(true);
            const loginUrl = await getBexioLoginUrl(token);
            setLoading(false);
            window.location.href = loginUrl;
        } catch (err) {
            console.log(err);
            setError(true);
            setLoading(false);
        }
    };
    const apaleoAccounting = useAccountingSchema(
        property ? property.code : null,
    );
    const bexioAccounting = useBexioAccountingSchema();
    const [bexioOptions, setBexioOptions] = React.useState<GroupedOption[]>([]);
    const [apaleoOptions, setApaleoOptions] = React.useState<GroupedOption[]>(
        [],
    );
    React.useEffect(() => {
        if (bexioAccounting.data) {
            const arr = convertBexioAccountingModel(bexioAccounting.data);
            setBexioOptions(arr);
        }
    }, [bexioAccounting.data]);
    React.useEffect(() => {
        if (apaleoAccounting.data) {
            let arr = [
                {
                    label: 'global accounts',
                    options: convertAccountingModel(
                        apaleoAccounting.data.globalAccounts,
                    ),
                },
                {
                    label: 'guest accounts',
                    options: convertAccountingModel(
                        apaleoAccounting.data.guestAccounts,
                    ),
                },
                {
                    label: 'external accounts',
                    options: convertAccountingModel(
                        apaleoAccounting.data.externalAccounts,
                    ),
                },
            ];
            if (state.mapping) {
                arr = mapDisabled(
                    arr,
                    state.mapping.map((x) => x.key),
                );
            }
            setApaleoOptions(arr);
        }
    }, [apaleoAccounting.data, state.mapping]);
    if (!property) {
        return <NotFound />;
    }
    return (
        <Base
            loading={
                loading ||
                initialDataState === 'loading' ||
                initialDataState === 'initial' ||
                bexioState.state === 'loading' ||
                bexioState.state === 'initial' ||
                bexioAccounting.state === 'initial' ||
                bexioAccounting.state === 'loading' ||
                apaleoAccounting.state === 'initial' ||
                apaleoAccounting.state === 'loading'
            }
        >
            <h1 className="font-bold text-3xl">{property.name}</h1>
            <div className="flex flex-col border border-black rounded bg-white p-2">
                <div className="flex flex-row">
                    <h1 className="font-bold mr-2">Bexio Accounting</h1>
                    <Switch
                        checked={state.enabled}
                        onChange={(v) => {
                            setState({ ...state, enabled: v });
                            patch({ enabled: v });
                        }}
                        disabled={patchState === 'loading'}
                    >
                        Enable
                    </Switch>
                </div>
                {(error || bexioState.state === 'error') && (
                    <span className="text-red-500">Error</span>
                )}
                <div className="flex flex-row">
                    <Button
                        className=""
                        disabled={
                            (bexioState.data &&
                                bexioState.data.status === 'ok') ||
                            !state.enabled
                        }
                        onClick={onLogin}
                    >
                        {bexioState.data && bexioState.data.status === 'ok' ? (
                            <span>Connected with Bexio</span>
                        ) : (
                            <span>Connect with Bexio</span>
                        )}
                    </Button>
                </div>
                <DefaultAccounts
                    options={bexioOptions}
                    debitAcc={state.defaultDebitAccount}
                    creditAcc={state.defaultCreditAccount}
                    onChange={(debitAcc, creditAcc) => {
                        const data = {
                            defaultDebitAccount: debitAcc ?? undefined,
                            defaultCreditAccount: creditAcc ?? undefined,
                        };
                        setState({ ...state, ...data });
                        patch(data);
                    }}
                    isDisabled={patchState === 'loading' || !state.enabled}
                />
                {state.mapping &&
                    state.mapping.map((item, idx) => (
                        <Row
                            key={idx}
                            aOptions={apaleoOptions}
                            bOptions={bexioOptions}
                            a={item.key}
                            b={item.value}
                            onChange={(a, b) => {
                                const mapping = state.mapping
                                    ? state.mapping.map((it, i) =>
                                          i === idx ? { key: a, value: b } : it,
                                      )
                                    : [];
                                setState({ ...state, mapping });
                                patch({ mapping });
                            }}
                            onDelete={() => {
                                const mapping = state.mapping
                                    ? state.mapping.filter((it, i) => i !== idx)
                                    : [];
                                setState({ ...state, mapping });
                                patch({ mapping });
                            }}
                            isDisabled={
                                patchState === 'loading' || !state.enabled
                            }
                        />
                    ))}
                <Row
                    key={state.mapping ? state.mapping.length : 0}
                    aOptions={apaleoOptions}
                    bOptions={bexioOptions}
                    onChange={(a, b) => {
                        const mapping = state.mapping ? state.mapping : [];
                        mapping.push({ key: a, value: b });
                        setState({ ...state, mapping });
                        patch({ mapping });
                    }}
                    isDisabled={patchState === 'loading' || !state.enabled}
                />
            </div>
        </Base>
    );
}
