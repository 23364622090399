export interface ButtonProps {
    className?: string;
    children?: React.ReactNode;
    disabled?: boolean;
    onClick?: () => void;
}

export function Button({ children, className, ...rest }: ButtonProps) {
    return (
        <button
            className={`bg-gray-200 hover:bg-gray-300 rounded border border-gray-500 disabled:text-gray-500 py-2 px-4 flex flex-row items-center justify-center ${className}`}
            {...rest}
        >
            {children}
        </button>
    );
}
