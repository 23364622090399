export const getCookie = (name: string): string | null => {
    const matches = document.cookie.match(
        // eslint-disable-next-line no-useless-escape
        new RegExp(
            '(?:^|; )' +
                name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1') +
                '=([^;]*)',
        ),
    );
    return matches ? decodeURIComponent(matches[1]) : null;
};

export const setCookie = (
    name: string,
    value: string | null,
    expires: number,
): void => {
    if (!value || expires <= 0) {
        document.cookie = `${name}=; Max-Age=0`;
    } else {
        const dt = new Date(expires);
        document.cookie = `${name}=${value}; expires=${dt.toUTCString()}`;
    }
};

export const range = (start: number, end: number): number[] => {
    return Array.from({ length: end - start }, (_, i) => start + i);
};
