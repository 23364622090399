import * as React from 'react';

export interface SwitchProps {
    className?: string;
    children?: React.ReactNode;
    checked?: boolean;
    onChange: (checked: boolean) => void;
    disabled?: boolean;
}

export function Switch({ children, checked, onChange, disabled }: SwitchProps) {
    return (
        <div className="flex justify-center">
            <div className="form-check form-switch">
                <input
                    className="form-check-input appearance-none w-9 -ml-10 rounded-full float-left h-5 align-top bg-white bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm"
                    type="checkbox"
                    role="switch"
                    checked={!!checked}
                    onChange={(e) => onChange(e.target.checked)}
                    disabled={!!disabled}
                />
                <label className="form-check-label inline-block text-gray-800">
                    {children}
                </label>
            </div>
        </div>
    );
}
