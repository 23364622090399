import { API_URL, axiosRequest } from './api';
import { getCookie, setCookie } from '../utils';

interface ApiToken {
    token: string;
}

export async function oauthCallback(
    state: string,
    code: string,
): Promise<string> {
    const { token } = await axiosRequest<ApiToken>({
        url: `${API_URL}/apaleo/oauth/callback`,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        data: JSON.stringify({ state, code }),
        responseType: 'json',
    });
    return token;
}

interface ApiLogin {
    loginUrl: string;
}

export async function getLoginUrl(): Promise<string> {
    const { loginUrl } = await axiosRequest<ApiLogin>({
        url: `${API_URL}/apaleo/oauth/login`,
        method: 'POST',
        responseType: 'json',
    });
    return loginUrl;
}

interface BexioCallbackStatus {
    status: string;
}

export async function bexioOAuthCallback(
    token: string | null,
): Promise<string | null> {
    const codeVerifier = getCookie('bexioCodeVerifier');
    const callbackUrl = getCookie('bexioCallbackUrl');
    const { status } = await axiosRequest<BexioCallbackStatus>({
        url: `${API_URL}/ui/user/oauth/bexio-apaleo/callback`,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        data: JSON.stringify({ url: window.location.href, codeVerifier }),
        responseType: 'json',
    });
    return status === 'ok' ? callbackUrl : null;
}

interface BexioLogin {
    url: string;
    codeVerifier: string;
}

export async function getBexioLoginUrl(token: string | null): Promise<string> {
    const { url, codeVerifier } = await axiosRequest<BexioLogin>({
        url: `${API_URL}/ui/user/oauth/bexio-apaleo`,
        method: 'GET',
        responseType: 'json',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    const now = new Date();
    const expires = new Date(now.valueOf() + 3600 * 1000);
    setCookie('bexioCodeVerifier', codeVerifier, expires.valueOf());
    setCookie('bexioCallbackUrl', window.location.href, expires.valueOf());
    return url;
}
