import * as React from 'react';
import { useParams } from 'react-router-dom';
import {
    PropertyContext,
    useTessinReportRequest,
    TessinSettings,
    useExportSettings,
    usePatchExportSettings,
    SubscriptionContext,
} from '../api';
import { Switch, DatePicker, TextField, Checkbox } from '../components';
import { Base, PageParams } from './Base';
import { NotFound } from './NotFound';

export function TessinExport(): JSX.Element {
    const { properties } = React.useContext(PropertyContext);
    const subscription = React.useContext(SubscriptionContext);
    const [state, setState] = React.useState<TessinSettings>(
        {} as TessinSettings,
    );
    const [date, setDate] = React.useState<Date | null>(new Date());
    const { id } = useParams<PageParams>();
    const property = properties.find((p) => p.code === id);
    const {
        getReport,
        state: reportState,
        error: reportError,
    } = useTessinReportRequest(property ? property.code : '');
    const { data: initialData, state: initialDataState } =
        useExportSettings<TessinSettings>('tessin', id);
    const {
        patch,
        state: patchState,
        data: patchData,
    } = usePatchExportSettings<TessinSettings>('tessin', id);
    React.useEffect(() => {
        if (initialData) {
            setState(initialData);
        }
    }, [initialData]);
    React.useEffect(() => {
        if (patchData) {
            setState(patchData);
        }
    }, [patchData]);
    if (!property) {
        return <NotFound />;
    }
    return (
        <Base
            loading={
                initialDataState === 'loading' ||
                initialDataState === 'initial' ||
                reportState === 'loading'
            }
        >
            <h1 className="font-bold text-3xl">{property.name}</h1>
            <div className="flex flex-col border border-black rounded bg-white p-2">
                <div className="flex flex-row">
                    <h1 className="font-bold mr-2">Guest Control Tessin</h1>
                    <Switch
                        checked={
                            state.enabled && subscription.tessin.subscription
                        }
                        onChange={(v) => {
                            if (!subscription.tessin.subscription) {
                                subscription.tessinCheckoutRedirect();
                            } else {
                                setState({ ...state, enabled: v });
                                patch({ enabled: v });
                            }
                        }}
                        disabled={patchState === 'loading'}
                    >
                        Subscribe
                    </Switch>
                </div>
                <div className="w-1/2 p-2">
                    <TextField
                        name="Tessin Property Id"
                        value={state.tessin_id}
                        onChange={(v) => setState({ ...state, tessin_id: v })}
                        onBlur={() => patch({ tessin_id: state.tessin_id })}
                        disabled={!state.enabled || patchState === 'loading'}
                    />
                </div>
                <Checkbox
                    checked={state.enable_emails}
                    onChange={(v) => {
                        setState({ ...state, enable_emails: v });
                        patch({ enable_emails: v });
                    }}
                    disabled={!state.enabled || patchState === 'loading'}
                >
                    Automatically send daily
                </Checkbox>
                <div className="w-1/2 p-2">
                    <TextField
                        name="Send mail to"
                        value={state.email_to}
                        onChange={(v) => setState({ ...state, email_to: v })}
                        onBlur={() => patch({ email_to: state.email_to })}
                        disabled={!state.enabled || patchState === 'loading'}
                    />
                </div>
                <span className={state.enabled ? '' : 'text-gray-500'}>
                    Download CSV
                </span>
                <div className="p-2 w-1/2">
                    <div>
                        <DatePicker
                            value={date}
                            onChange={(date) => setDate(date)}
                            label="Date:"
                        />
                        <button
                            className="bg-gray-200 rounded border border-gray-500 disabled:text-gray-500 py-2 px-4 flex flex-row items-center justify-center mt-2"
                            disabled={
                                !state.enabled || reportState === 'loading'
                            }
                            onClick={() => getReport(date)}
                        >
                            <span className="mr-2">Export</span>
                        </button>
                    </div>
                    {reportState === 'error' && (
                        <span className="text-red-500">{reportError}</span>
                    )}
                </div>
            </div>
        </Base>
    );
}
